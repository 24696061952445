import "./introEvents.css";

const IntroEvents = () => {
  return (
    <div className="container__IntroEvents">
      <div className="row__IntroEvents">
        <div className="left__IntroEvents">
          <span className="topHeading__IntroEvents title__top">
            COMMUNITY & EVENTS
          </span>
          <span className="heading__IntroEvents title__section">
            Connecting Minds, Creating Solutions
          </span>
          <p className="text__IntroEvents description">
            Our events unite tech enthusiasts to showcase blockchain projects,
            solve real-world challenges, and build strong communities across
            Africa.
          </p>
          <a
            href="/about"
            className="button__about button ActionOne interface shadow1"
            target="_blank"
            rel="noopener noreferrer"
          >
            LEARN MORE
          </a>
        </div>
        <div className="right__IntroEvents shadow">
          <img
            src="/images/objects/community.svg"
            alt="3d shape glowing with bright holographic colors by Freepik"
            className="object__IntroEvents"
          />
        </div>
      </div>
    </div>
  );
};

export default IntroEvents;
