import "./mission.css";

const Mission = () => {
  return (
    <div className="container__Mission">
      <div className="row__Mission">
        <div className="top__Mission">
          <h3 className="topHeading__Mission title__top">OUR MISSION</h3>
          <h2 className="heading__Mission title3">
            Unifying Africa’s Blockchain Ecosystem
          </h2>
          <p className="description__Mission">
            UABA’s mission is to unify the African blockchain ecosystem toward
            achieving eight core goals that embody coexistence and progress
          </p>
        </div>
        <div className="down__Mission">
          {/* card 1 */}
          <div className="card__Mission">
            <img
              src="/images/icons/education.png"
              alt=""
              className="icon--card__Mission"
            />
            <h4 className="heading--card__Mission">Education</h4>
            <p className="description--card__Mission">
              Providing accessible blockchain education to empower individuals
              and communities with the knowledge and skills needed for the
              digital economy.
            </p>
          </div>
          {/* card 2 */}
          <div className="card__Mission">
            <img
              src="/images/icons/inclusion.png"
              alt=""
              className="icon--card__Mission"
            />
            <h4 className="heading--card__Mission">Financial Inclusion</h4>
            <p className="description--card__Mission">
              Promoting blockchain technology to create inclusive financial
              systems that allow all Africans, including the unbanked, to access
              secure and transparent financial services.
            </p>
          </div>
          {/* card 3 */}
          <div className="card__Mission">
            <img
              src="/images/icons/identity.png"
              alt=""
              className="icon--card__Mission"
            />
            <h4 className="heading--card__Mission">Sovereign Identity</h4>
            <p className="description--card__Mission">
              Using blockchain to enable secure, decentralized systems for
              identity management, giving individuals full control over their
              personal data and credentials.
            </p>
          </div>

          {/* card 4 */}
          <div className="card__Mission">
            <img
              src="/images/icons/skills.png"
              alt=""
              className="icon--card__Mission"
            />
            <h4 className="heading--card__Mission">Skills Development</h4>
            <p className="description--card__Mission">
              Equipping Africans with blockchain and digital skills to prepare
              them for emerging jobs and opportunities in the technology sector.
            </p>
          </div>
          {/* card 5 */}
          <div className="card__Mission">
            <img
              src="/images/icons/job.png"
              alt=""
              className="icon--card__Mission"
            />
            <h4 className="heading--card__Mission">Job Creation</h4>
            <p className="description--card__Mission">
              Supporting the growth of blockchain startups and enterprises to
              generate new job opportunities across the continent.
            </p>
          </div>
          {/* card 6 */}
          <div className="card__Mission">
            <img
              src="/images/icons/gender.png"
              alt=""
              className="icon--card__Mission"
            />
            <h4 className="heading--card__Mission">Gender Equality</h4>
            <p className="description--card__Mission">
              Fostering equal opportunities in the blockchain space by promoting
              the inclusion and participation of women in technology and
              leadership roles.
            </p>
          </div>
          {/* card 7 */}
          <div className="card__Mission">
            <img
              src="/images/icons/trade.png"
              alt=""
              className="icon--card__Mission"
            />
            <h4 className="heading--card__Mission">Fair and Efficient Trade</h4>
            <p className="description--card__Mission">
              Leveraging blockchain to create transparent, efficient, and
              equitable trade systems that benefit local businesses and
              economies.
            </p>
          </div>
          {/* card 8 */}
          <div className="card__Mission">
            <img
              src="/images/icons/poverty.png"
              alt=""
              className="icon--card__Mission"
            />
            <h4 className="heading--card__Mission">Poverty Alleviation</h4>
            <p className="description--card__Mission">
              Implementing blockchain-driven solutions that address social and
              economic challenges, empowering communities and reducing poverty
              across Africa.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
