import React from "react";
import "./navbar.css";

const Navbar = () => {
  return (
    <nav>
      <div className="nav-container interface">
        <div className="left logo">
          <a href="/">
            <img src="/logo.png" alt="UABA Logo" />
          </a>
        </div>
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
        <ul className="right nav-links">
          <label htmlFor="close-btn" className="btn close-btn">
            <i className="fas fa-times" />
          </label>
          <li>
            <a href="/">HOME</a>
          </li>
          <li>
            <a href="/about">OVERVIEW</a>
          </li>
          <li>
            <a href="/learn">LEARN</a>
          </li>
          <li>
            <a href="/participate">PARTICIPATE</a>
          </li>
          <li>
            <a href="/hub">HUB</a>
          </li>
          <li>
            <a href="/contact">CONTACT US</a>
          </li>
        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn">
          <i className="fas fa-bars" />
        </label>
      </div>
    </nav>
  );
};

export default Navbar;
