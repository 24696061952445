import "./introEdu.css";

const IntroEdu = () => {
  return (
    <div className="container__IntroEdu">
      <div className="row__IntroEdu">
        <div className="right__IntroEdu shadow">
          <img
            src="/images/objects/education.svg"
            alt=""
            className="object__IntroEdu"
          />
        </div>
        <div className="left__IntroEdu">
          <span className="topHeading__IntroEdu title__top">
            EDUCATION & ADVOCACY
          </span>
          <span className="heading__IntroEdu title__section">
            Building Skills, Inspiring Change
          </span>
          <p className="text__IntroEdu description">
            Educating 1 million Africans on blockchain and fostering diversity,
            inclusion, and innovation through hands-on training and mentorship.
          </p>
          <a
            href="/about"
            className="button__about button ActionOne interface shadow1"
            target="_blank"
            rel="noopener noreferrer"
          >
            LEARN MORE
          </a>
        </div>
      </div>
    </div>
  );
};

export default IntroEdu;
