import "./pillars.css";

const Pillars = () => {
  return (
    <div className="container__Pillars">
      <div className="row__Pillars">
        <div className="heading__Pillars">
          <h3 className="topHeading__Pillars title__top">OUR WORK</h3>
          <h2 className="heading__Pillars title3">Our Strategic Pillars</h2>
          <p className="description__Pillars">
            Guided by a commitment to education, engagement, and innovation,
            UABA's strategic pillars focus on creating opportunities through
            awareness and driving blockchain adoption across Africa. Our
            approach ensures inclusive growth and sustainable implementation of
            emerging technologies.
          </p>
        </div>
        <div className="awareness__Pillars">
          <h3 className="topHeading__Pillars">EDUCATION & AWARENESS</h3>
          <h2 className="heading-awareness__Pillars">
            Creating Opportunities Through Education
          </h2>
          <p className="description__Pillars">
            Unlocking the potential of technological advancements starts with
            providing access to education. By fostering equal opportunities for
            all, education becomes the cornerstone for progress and inclusion.
            UABA’s awareness campaigns aim to harness the power of emerging
            technologies to create meaningful opportunities across Africa.
            <br />
            <br />
            {/* <a href="/" className="link__Pillars">
              Discover how UABA is using education to bridge the digital divide
              and empower communities through blockchain technology.
            </a> */}
          </p>
        </div>
        <div className="advocacy__Pillars">
          <h3 className="topHeading__Pillars">ADOPTION AND ADVOCACY</h3>
          <h2 className="heading-awareness__Pillars">
            Driving Stakeholder Engagement for Lasting Impact
          </h2>
          <p className="description__Pillars">
            Successful adoption of emerging technologies hinges on effective
            stakeholder engagement. This involves understanding the unique needs
            of each sector and delivering tailored solutions. UABA actively
            engages with key stakeholders from the private sector, public
            institutions, and communities to ensure a collaborative approach to
            blockchain implementation. 
            <br />
            <br />
            {/* <a href="/" className="link__Pillars">
              Explore how UABA is fostering partnerships to drive widespread
              blockchain adoption across Africa.
            </a> */}
          </p>
        </div>
        <div className="Image__Pillars">
          <img src="/images/about/pillars.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Pillars;
