import "./initiatives.css";

const Initiatives = () => {
  return (
    <div className="container__Initiatives">
      <div className="row__Initiatives">
        <div className="title__Initiatives">
          <span className="topHeading__Initiatives title__top">
            OUR INITIATIVES
          </span>
          <span className="heading__Initiatives title__section">
            Pioneering Change with Blockchain
          </span>
          <p className="description__Initiatives description">
            From training future leaders to fostering groundbreaking startups,
            our initiatives are transforming Africa’s digital landscape through
            blockchain technology.
          </p>
        </div>
        <div className="cards__Initiatives">
          {/* Card1 */}
          <div className="card shadow1">
            <div className="card_left">
              <img
                src="/images/initiatives/techcon.svg"
                className="card_icon"
                alt="TechCon Logo"
              />
              <a
                href="/techcon"
                className="smallAction interface"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>
            <div className="card_right">
              <p>
                TechCon is our flagship conference that gathers blockchain
                professionals and enthusiasts to discuss the latest trends,
                challenges, and opportunities in the blockchain space.
                Participants have the chance to learn from top industry experts,
                share ideas, and network.
              </p>
            </div>
          </div>
          {/* Card2 */}
          <div className="card shadow1">
            <div className="card_left">
              <img
                src="/images/initiatives/1mb.svg"
                className="card_icon"
                alt="One Million For BLockchain Program Logo"
              />
              <a
                href="/omb"
                className="smallAction interface"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>
            <div className="card_right">
              <p>
                The 1MB Program is a groundbreaking campaign aimed at raising
                awareness and promoting blockchain education across Africa. By
                equipping stakeholders with essential blockchain skills and
                tools, we aim to bring blockchain technology to every corner of
                the continent.
              </p>
            </div>
          </div>
          {/* Card3 */}
          <div className="card shadow1">
            <div className="card_left">
              <img
                src="/images/initiatives/ttt.svg"
                className="card_icon"
                alt="Train The Trainer Program Logo"
              />
              <a
                href="/ttt"
                className="smallAction interface"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>
            <div className="card_right">
              <p>
                The Train The Train program is designed to empower local
                educators and leaders with blockchain knowledge, allowing them
                to train others in schools, communities, government offices, and
                the private sector. This program fosters a multiplying effect,
                spreading blockchain literacy across Africa.
              </p>
            </div>
          </div>
          {/* Card4 */}
          <div className="card shadow1">
            <div className="card_left">
              <img
                src="/images/initiatives/academy.svg"
                className="card_icon"
                alt="UABA Academy logo"
              />
              <a
                href="/academy"
                className="smallAction interface"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>
            <div className="card_right">
              <p>
                Our UABA Academy is the hub for blockchain education, offering
                online courses and certifications to prepare Africans for the
                digital future. With tailored programs for both beginners and
                experts, the Academy is committed to equipping the next
                generation of blockchain leaders.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Initiatives;
