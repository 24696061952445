import "./headerAbout.css";

const HeaderAbout = () => {
  return (
    <div className="container__headerAbout">
      <div className="row__headerAbout">
        <h3 className="topHeading__headerAbout title__top">ABOUT UABA</h3>
        <h1 className="heading__headerAbout title2">
          Africa’s Blockchain Pioneers
        </h1>
        <p className="description__headerAbout">
          The United Africa Blockchain Association (UABA) is a tech-focused
          nonprofit organization with the goal of fostering blockchain
          education, innovation, and adoption across Africa. Headquartered in
          South Africa, UABA collaborates with both private and public sectors,
          empowering Africans to benefit from decentralized and transparent
          technology.
        </p>
      </div>
    </div>
  );
};

export default HeaderAbout;
