import './contactForm.css';
import Navbar from "../../components/navbar/Navbar";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/Footer";

const FormContact = () => {
  return (
    <div>
      <nav>
        <Navbar />
      </nav>
      <main className='content_Form'>
        <Contact />
      </main>
      <footer>
        <Footer/>
      </footer>
    </div>
  );
};

export default FormContact;
