import Intro from "../../components/about/Intro";
import IntroEdu from "../../components/about/IntroEdu";
import IntroEvents from "../../components/about/IntroEvents";
import Contact from "../../components/contact/Contact";
import Events from "../../components/events/Events";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Initiatives from "../../components/initiatives/Initiatives";
import Membership from "../../components/membership/Membership";
import Navbar from "../../components/navbar/Navbar";
import Partners from "../../components/partners/Partners";
import Resources from "../../components/resources/Resources";
import "./home.css";

const Home = () => {
  return (
    <div className="container__home">
      <nav>
        <Navbar />
      </nav>
      <header id="hero">
        <div className="container__header">
          <Header />
        </div>
      </header>
      <main>
        <article id="intro">
          <Intro />
        </article>
        <article id="education">
          <IntroEdu />
        </article>
        <article id="events">
          <IntroEvents />
        </article>
        <article id="initiatives">
          <Initiatives />
        </article>
        <article id="news">
          <Events />
        </article>
        <article id="partners">
          <Partners />
        </article>
        <article id="membership">
          <Membership />
        </article>
        <article id="resources">
          <Resources />
        </article>
        <article id="contact">
          <Contact />
        </article>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Home;
