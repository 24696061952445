import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import "./resources.css";

// TO DO: create a remember to have menu items in the sidebar that go to the menu items in the mega menu items

const Hub = () => {
  return (
    <>
      <nav>
        <Navbar />
      </nav>
      <header>
        <div className="container__hub-header">
          <div className="row__hub-header">
            <div className="title__hub-header shadow">
              <h3 className="topheading__Overview title__top">RESOURCES</h3>
              <h1 className="heading__Overview title3">
                Blockchain Knowledge Hub
              </h1>
              <p className="description__Overview">
                Discover, Learn, and Innovate with Africa’s Leading Blockchain
                Resources Dive into expert insights, comprehensive guides, and
                essential tools to navigate the world of blockchain. Explore our
                blog, knowledge hub, and FAQ to stay informed and empowered.
              </p>
              <a href="/" className="button ActionOne interface shadow1">
                GET STARTED
              </a>
            </div>
            <img
              src="/images/objects/robot.png"
              alt=""
              className="headerImage"
            />
          </div>
        </div>
      </header>
      <article className="sticky stickyStuff"></article>
      <main>
        <div className="container__sidebar">
          <div className="left__sidebar">
            <div className="sideMenu">
              <h2 className="heading__sideMenu title__top">ON THIS PAGE</h2>
              <div className="Link__sideMenu">
                <a href="#overview">Overview</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#blog">Blog</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#hub">Knowledge Hub</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#faqs">FAQs</a>
              </div>
            </div>
          </div>
          <div className="right__sidebar flow">
            <h2 id="overview">Knowledge Hub Overview</h2>
            <p>
              Welcome to the UABA Knowledge Hub, your gateway to understanding
              blockchain technology and its transformative impact across Africa.
              Whether you're a curious beginner or a seasoned expert, our
              resources are designed to enhance your knowledge and help you
              navigate this rapidly evolving digital landscape.
            </p>
            <div className="row_grid__omb">
              {/* Card 1 */}
              <div id="blog" className="card__omb">
                <img src="/images/resources/blog.jpg" alt="" />
                <h3>Blog: Expert Insights & Updates</h3>
                <p>
                  Stay ahead of the curve with thought-provoking articles,
                  expert insights, and the latest developments in the blockchain
                  space. Our blog covers everything from key industry trends and
                  project case studies to beginner-friendly guides and technical
                  deep dives.
                </p>
                <ul>
                  <li>Real-world applications of blockchain in Africa</li>
                  <li>Interviews with industry leaders</li>
                  <li>In-depth analysis of the latest blockchain trends</li>
                </ul>
                {/* <a
                  href="/"
                  className="button-card__hub button ActionOne interface shadow1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MORE ON BLOG
                </a> */}
              </div>
              {/* Card 2 */}
              <div className="card__omb">
                <img id="hub" src="/images/resources/hub.jpg" alt="" />
                <h3> Knowledge Hub: Whitepapers, Reports & Case Studies</h3>
                <p>
                  Discover a treasure trove of downloadable resources, including
                  research papers, case studies, and reports that spotlight the
                  use of blockchain across various sectors in Africa. This
                  library is your go-to for technical insights and business
                  applications of blockchain technology.
                </p>
                <ul>
                  <li>Comprehensive whitepapers on blockchain technology</li>
                  <li>Case studies from UABA’s initiatives</li>
                  <li>Research reports on blockchain adoption in Africa</li>
                </ul>
                {/* <a
                  href="/"
                  className="button-card__hub button ActionOne interface shadow1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MORE ON THE HUB
                </a> */}
              </div>
              {/* Card 3 */}
              <div id="faqs" className="card__omb">
                <img src="/images/resources/faqs.jpg" alt="" />
                <h3>FAQ: Your Blockchain Questions Answered</h3>
                <p>
                  New to blockchain or just looking for quick answers? Our FAQ
                  section addresses common queries about blockchain technology,
                  how UABA operates, and ways to get involved. It's the perfect
                  place to start if you're exploring blockchain for the first
                  time.
                </p>
                <ul>
                  <li>Blockchain basics explained</li>
                  <li>How UABA supports blockchain adoption in Africa</li>
                  <li>Ways to join or support our initiatives</li>
                </ul>
                {/* <a
                  href="/"
                  className="button-card__hub button ActionOne interface shadow1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO FAQS
                </a> */}
              </div>
            </div>
            <h2>Start Exploring Today</h2>
            <p>
              Whether you're here to learn or contribute, our resources are
              designed to help you dive deeper into blockchain technology and
              understand its potential for African development. Explore,
              download, and engage with our growing collection of knowledge!
            </p>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default Hub