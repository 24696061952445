import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./academy.css";

const Academy = () => {
  return (
    <div>
      {/* Navbar */}
      <nav>
        <Navbar />
      </nav>
      {/* Header */}
      <header className="container-header__Academy">
        <div className="row-header__Academy">
          <div className="left-header__Academy">
            <h3 className="title__top">UABA Academy</h3>
            <h1 className="heading-header__Academy title2">
              Unlock the potential of blockchain with accessible, high-quality
              education
            </h1>
            <p>
              The UABA Academy is committed to providing education, training,
              and mentorship in blockchain technology across Africa. Whether
              you're new to blockchain or looking to advance your skills, we
              offer courses for everyone.
            </p>
            <div className="cta-header__Academy interface">
              <a
                href="/contact"
                className="button_header__Academy button ActionOne shadow1"
                target="_blank"
                rel="noopener noreferrer"
              >
                GET STARTED
              </a>
            </div>
          </div>
          <div className="right-header__Academy">
            <img
              src="/images/objects/academy.svg"
              alt=""
              className="Image-container__Academy"
            />
          </div>
        </div>
      </header>
      <main className="pageColor">
        {/* Courses */}
        <article className="container-courses__Academy">
          <div className="heading-courses__Academy">
            <h3 className="topHeading-courses_Academy title__top">
              OUR COURSES
            </h3>
            <h2 className="title3">Explore, Learn, Lead</h2>
            <p className="description-courses__Academy">
              Master the fundamentals of blockchain technology with courses
              designed to equip you with the skills needed to shape Africa’s
              digital future.
            </p>
          </div>
          <div className="row-courses__Academy">
            {/* Course 1 */}
            <div className="course shadow">
              <img
                src="/images/academy/course1.svg"
                alt="Blockchain Fundamentals"
                className="ImageCourse"
              />
              <h3>Blockchain Fundamentals</h3>
              <p>
                A beginner-friendly course covering the basics of blockchain,
                its use cases.
              </p>
              <p>
                <strong>Who It's For:</strong> Students, professionals, and
                anyone new to blockchain.
              </p>
              <p>
                <strong>Key Topics:</strong> Introduction to Blockchain,
                Cryptography, Blockchain Networks, Uses Cases.
              </p>
              <p className="cta_courses">
                <a
                  href="/contact"
                  className="smallAction interface cta_button_courses"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Course
                </a>
              </p>
            </div>
            {/* Course 2 */}
            <div className="course shadow">
              <img
                src="/images/academy/course2.svg"
                alt="Advanced Blockchain Solutions"
                className="ImageCourse"
              />
              <h3>Advanced Blockchain Solutions</h3>
              <p>
                A deep dive into how blockchain can solve complex problems in
                various sectors like finance and healthcare.
              </p>
              <p>
                <strong>Who It's For:</strong> Business leaders, IT
                professionals, and developers.
              </p>
              <p>
                <strong>Key Topics:</strong> Enterprise Blockchain Solutions,
                Smart Contracts, Decentralized Finance (DeFi).
              </p>
              <p className="cta_courses">
                <a
                  href="/contact"
                  className="smallAction interface cta_button_courses"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Course
                </a>
              </p>
            </div>
            {/* Course 3 */}
            <div className="course shadow">
              <img
                src="/images/academy/course3.svg"
                alt="Train the Trainer Program"
                className="ImageCourse"
              />
              <h3>Train the Trainer Program</h3>
              <p>
                Designed for educators and community leaders who wish to train
                others in blockchain technology.
              </p>
              <p>
                <strong>Who It's For:</strong> Teachers, mentors, leaders,
                community leaders.
              </p>
              <p>
                <strong>Key Topics:</strong> Blockchain Curriculum Development,
                Teaching Blockchain, Training Methodologies.
              </p>
              <p className="cta_courses">
                <a
                  href="/contact"
                  className="smallAction interface cta_button_courses"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Course
                </a>
              </p>
            </div>
          </div>
        </article>
        {/* Modes Of Training */}
        <article className="container__how-it-works">
          <div className="row__how-it-works">
            <div className="title__how-it-works flow">
              <h2 className="heading__how-it-works title3">How It Works</h2>
              <p className="description__how-it-works">
                UABA Academy offers flexible learning options to suit your needs
              </p>
            </div>
            <div className="ways-to-train">
              <div className="modes">
                <img
                  src="/images/academy/online.svg"
                  alt="Online Courses"
                  className="Icon__modes"
                />
                <h3>Online Courses</h3>
                <p>Access our library of courses anytime, anywhere.</p>
              </div>
              <div className="modes">
                <img
                  src="/images/academy/inperson.svg"
                  alt="Online Courses"
                  className="Icon__modes"
                />
                <h3>In-Person Workshops</h3>
                <p>Join interactive sessions in various African cities.</p>
              </div>
              <div className="modes">
                <img
                  src="/images/academy/certification.svg"
                  alt="Online Courses"
                  className="Icon__modes"
                />
                <h3>Certifications</h3>
                <p>Earn certificates that validate your blockchain skills.</p>
              </div>
            </div>
          </div>
        </article>
        <article className="testimonials">
          <div className="title_testimonials__Academy">
            <h3 className="topheading-testimonials__Academy title__top">
              Testimonials
            </h3>
            <h2 className="heading-testimonials__Academy title3">
              Success Stories
            </h2>
            <p className="description-testimonials__Academy">
              See how UABA Academy has helped individuals and organizations
              across Africa unlock the potential of blockchain technology,
              driving real change and innovation in their communities.
            </p>
          </div>
          <section className="container_testimonials__Academy">
            <div className="slider">
              <input
                type="radio"
                name="slider"
                title="slide1"
                defaultChecked="checked"
                className="slider__nav"
              />
              <input
                type="radio"
                name="slider"
                title="slide2"
                className="slider__nav"
              />
              <input
                type="radio"
                name="slider"
                title="slide3"
                className="slider__nav"
              />
              <input
                type="radio"
                name="slider"
                title="slide4"
                className="slider__nav"
              />
              <div className="slider__inner">
                <div className="slider__contents">
                  <p className="slider__txt">
                    "It has been such an enriching experience...I have learnt a
                    lot during the training. Thank you."
                  </p>
                  <p>
                    <span>- Wanjohi Muthoni </span>
                  </p>
                </div>
                <div className="slider__contents">
                  <p className="slider__txt">
                    “The classes have been really good. I've learned more than I
                    thought Blockchain was and the notes given outside of class
                    are a good reference for later”
                  </p>
                  <p>
                    <span>- Sasha Annamercy</span>
                  </p>
                </div>
                <div className="slider__contents">
                  <p className="slider__txt">
                    "I have also enjoyed the classes very much, very well
                    structured content"
                  </p>
                  <p>
                    <span>- Hardy Wolv</span>
                  </p>
                </div>
                <div className="slider__contents">
                  {/* <i className="slider__image fa fa-diamond" />
                  <h2 className="slider__caption">diamond</h2> */}
                  <p className="slider__txt">
                    "This training has expanded my horizon, it came at a time
                    when I was searching for a training on Blockchain. It was
                    timely."
                  </p>
                  <p>
                    <span>- Muhammad Pedro</span>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </article>
        <article className="container_join bg2">
          <div className="row__join">
            <h2 className="title3">Join the Academy</h2>
            <p className="description__join">
              Ready to begin your blockchain journey? Enroll today in one of our
              courses and become part of Africa's growing blockchain community.
            </p>
            <div className="bottom__join interface">
              <a href="/contact" className="button ActionOne shadow1">
                SIGN UP FOR COURSES
              </a>
              <a href="/contact" className="button ActionTwo shadow1">
                CONTACT US
              </a>
            </div>
          </div>
        </article>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Academy;
