import "./contact.css";

const Contact = () => {
  return (
    <div className="container__Contact">
      {/* <img
        src="/images/bgs/contact.svg"
        alt="Digital background vector in blue color with cube patterns by Rawpixel.com"
        className="BgImage__Contact"
      /> */}
      <div className="row__container">
        <div className="left__Contact">
          <span className="topheading__Contact title__top">Contact Us</span>
          <span className="heading__Contact title__section">
            We’re Here to Help. Let's Connect.
          </span>
          <span className="description__Contact description">
            Whether you have inquiries, feedback, or ideas, we’re just a message
            away. Contact us for more information on partnerships, events, or
            how you can contribute to our mission. We’d love to hear from you.
          </span>
        </div>
        <div className="right__Contact shadow">
          <label htmlFor="name">FULL NAME</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="e.g. John Doe"
            className="fields shadow"
          />
          <label htmlFor="email">EMAIL ADDRESS</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="email@email.com"
            className="fields shadow"
          />
          <label htmlFor="reference">YOUR REFERENCE</label>
          <input
            type="text"
            id="reference"
            name="reference"
            placeholder="e.g. general, partnership, events"
            className="fields shadow"
          />
          <label htmlFor="message">MESSAGE</label>
          <textarea
            id="message"
            rows={8}
            cols="50"
            placeholder=" Write message here we will surely get back to you as soon as possible..."
            className="shadow"
          ></textarea>
          <div className="buttons__Contact">
            <input
              type="button"
              value="SUBMIT"
              className="button ActionOne interface shadow1"
            />
            <input
              type="reset"
              value="RESET"
              className="button ActionOne interface shadow1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
