import "./partners.css";

const Partners = () => {
  return (
    <div className="container__Partners">
      <div className="title__Partners">
        <h3 className="topHeading__Partners title__top">
          PARTNERSHIP & COLLABORATION
        </h3>
        <h2 className="heading__Partners title__section">
          Collaborating for Impact
        </h2>
        <p className="description__Partners description">
          Our partnerships unite visionaries, governments, and businesses to
          push the boundaries of blockchain technology and foster inclusive
          growth.
        </p>
      </div>

      <section className="logos__Partners">
        {/* Current Partners */}
        <div className="card__Partners">
          <h3 className="heading-cards__Partners">
            Current Partners & Collaborations
          </h3>
          <div className="row-card__Partners">
            <div className="icon__partner shadow1">
              <img src="/images/partners/tether.svg" alt="Tether Logo" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/tezos.svg" alt="Tezos Logo" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/stellar.svg" alt="Stellar Logo" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/icp.svg" alt="ICP Logo" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/mw3.svg" alt="Msanzi Web3 Logo" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/phenix.svg" alt="Phenix Logo" />
            </div>
            <div className="icon__partner shadow1">
              <img
                src="/images/partners/delight.svg"
                alt="Delight Productions Logo"
              />
            </div>
            <div className="icon__partner shadow1">
              <img
                src="/images/partners/ega.svg"
                alt="e-Government Agency of Tanzania"
              />
            </div>
          </div>
        </div>

        {/* Past Partners */}
        <div className="card__Partners">
          <h3 className="heading-cards__Partners">
            Previous Partners & Collaborations
          </h3>
          <div className="row-card__Partners">
            <div className="icon__partner shadow1">
              <img src="/images/partners/celo.svg" alt="Celo" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/ethereum.svg" alt="Ethereum" />
            </div>
            <div className="icon__partner shadow1">
              <img
                src="/images/partners/near.svg"
                alt="Near Blockchain Platform"
              />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/yellowcard.svg" alt="YellowCard" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/valr.svg" alt="Valr Logo" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/est.svg" alt="EST Group" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/yolo.svg" alt="Yolo Investments" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/probase.svg" alt="ProBase" />
            </div>
            <div className="icon__partner shadow1">
              <img src="/images/partners/bongo.svg" alt="BongoHive" />
            </div>
            <div className="icon__partner shadow1">
              <img
                src="/images/partners/szi.svg"
                alt="SMART Zambia Institute"
              />
            </div>
            <div className="icon__partner shadow1">
              <img
                src="/images/partners/mots.svg"
                alt="Ministry Of Technology & Science Zambia"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partners;
