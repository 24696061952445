import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./initiativesPg.css";

const Ttt = () => {
  return (
    <>
      <nav>
        <Navbar />
      </nav>
      <header className="container-header__Article ttt-header">
        <div className="row-header__Article wrapper__Article">
          <div className="header__Article wrapper__Article">
            <h3 className="topHeading__Article title__top">INITATIVES</h3>
            <h2 className="heading__Article title3">
              Train The Trainer (TTT) Program
            </h2>
          </div>
        </div>
      </header>
      <main className="main__Article">
        <article className="wrapper__Article flow">
          <p>
            The Train the Trainer (TTT) Program is a cornerstone of UABA’s
            commitment to accelerating blockchain adoption across Africa.
            Designed to equip individuals with in-depth blockchain knowledge,
            this program empowers participants to become trainers who will, in
            turn, educate others within their communities, creating a ripple
            effect of awareness and expertise.
          </p>
          <div className="flow">
            <h3>Building a Network of Blockchain Educators</h3>
            <p>
              At its core, the TTT Program is a scalable, sustainable model
              aimed at reaching UABA’s ambitious goal of educating 1 million
              people about blockchain technology. By focusing on developing
              skilled trainers, UABA ensures that blockchain knowledge is
              distributed far and wide, reaching even the most remote
              communities in Africa.
            </p>
            <p>
              Participants in the TTT program are carefully selected from
              various sectors, including education, technology, business, and
              government. Once trained, these individuals return to their
              communities equipped to deliver blockchain education and advocacy
              tailored to local needs, fostering grassroots blockchain adoption
              across the continent.
            </p>
          </div>
          <div className="flow">
            <h3>Program Structure</h3>
            <p>The TTT program is implemented in three phases:</p>
            <ol className="flow">
              <li>
                <h4>Intensive Blockchain Education</h4>
                <p>
                  Participants begin with comprehensive training on blockchain
                  fundamentals, covering the technology’s architecture, use
                  cases, and potential to transform industries. This phase
                  ensures that trainers are fully equipped with the technical
                  knowledge required to teach blockchain concepts effectively.
                </p>
              </li>
              <li>
                <h4>Trainer Development</h4>
                <p>
                  This phase focuses on empowering participants with the skills
                  necessary to become effective educators. Beyond blockchain
                  knowledge, trainers are equipped with teaching methodologies,
                  presentation skills, and communication strategies to
                  effectively engage their audiences, whether they are speaking
                  to students, professionals, or community leaders.
                </p>
              </li>
              <li>
                <h4>Community Engagement and Implementation</h4>
                <p>
                  Once the training is complete, participants are supported in
                  their efforts to implement blockchain education programs
                  within their own communities. UABA offers continued
                  mentorship, resources, and access to educational materials,
                  ensuring that trainers have everything they need to launch and
                  sustain their own local blockchain initiatives.
                </p>
              </li>
            </ol>
          </div>
          <div className="flow">
            <h3>A Catalyst for Mass Adoption</h3>
            <p>
              The Train the Trainer Program is more than just an educational
              initiative; it is a strategic movement designed to empower African
              communities from within. By creating a network of blockchain-savvy
              educators, UABA is laying the groundwork for widespread blockchain
              adoption and building a foundation for future innovation,
              entrepreneurship, and economic growth across Africa.
            </p>
          </div>
        </article>
        <article className="wrapper__Article flow">
          {/* <div className="title__case-card">
            <h2 className="heading__case-card">TTT Projects</h2>
          </div> */}

          {/* Card 1 */}
          <div className="row-case-card__Article">
            {/* <div className="case-card__Article">
              <img
                src="/images/projects/celo.svg"
                alt="TTT21"
                className="Image__case-card"
              />
              <h4 className="topHeading__case-card title__top">TTT Project</h4>
              <h3 className="heading__case-card">TTT 2021</h3>
              <p className="description__case-card">
                UABA and Celo partnered to conduct Train-the-Train 2021 (TTT21)
                to educate people in Africa on blockchain technology and the
                Celo ecosystem.
              </p>
              <a href="/celo" className="smallAction interface cta__case-card">
                Learn More
              </a>
            </div> */}
            {/* Card 2 */}
            {/* <div className="case-card__Article">
              <img
                src="/images/projects/wit2023.svg"
                alt="TTT21"
                className="Image__case-card"
              />
              <h4 className="topHeading__case-card title__top">TTT Project</h4>
              <h3 className="heading__case-card">WIT Blockchain Program</h3>
              <p className="description__case-card">
                UABA Women In Tech (WIT) partnered to conduct to women who were
                to train fellow women in 5 countries in Africa on Blockchain
                Technology and its uses.
              </p>
              <a href="/" className="smallAction interface cta__case-card">
                Learn More
              </a>
            </div> */}
          </div>
        </article>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default Ttt;
