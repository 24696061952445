import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./initiativesPg.css";

const OneMb = () => {
  return (
    <>
      <nav>
        <Navbar />
      </nav>
      <header className="container-header__Article omb-header">
        <div className="row-header__Article wrapper__Article">
          <div className="header__Article wrapper__Article">
            <h3 className="topHeading__Article title__top">INITATIVES</h3>
            <h2 className="heading__Article title3">
              1MB Campaign: One Million for Blockchain
            </h2>
          </div>
        </div>
      </header>
      <main className="main__Article">
        <article className="wrapper__Article flow">
          <p>
            The One Million for Blockchain (1MB) campaign is UABA’s flagship
            initiative designed to promote widespread blockchain awareness and
            adoption across Africa. By providing educational programs and
            practical training, the campaign empowers individuals to understand
            and utilize blockchain technology for real-world applications and
            economic growth.
          </p>
          <p>
            The 1MB campaign is structured into three phases, each aimed at
            progressively deepening participants' blockchain knowledge and
            entrepreneurial skills.
          </p>
          <div className="flow">
            <h3>Phase 1: Foundational Learning</h3>
            <p>
              The first phase focuses on eliminating blockchain illiteracy by
              offering free educational programs. Participants gain a
              comprehensive understanding of blockchain fundamentals and its
              potential for transforming industries. Over a dedicated period,
              learners engage with curated content, participate in interactive
              sessions, and complete foundational courses to build their
              knowledge base.
            </p>
          </div>
          <div className="flow">
            <h3>Phase 2: Bridging Knowledge to Application</h3>
            <p>
              In the second phase, participants who have mastered the
              foundational concepts are introduced to how Blockchain as a
              Service (BaaS) operates. This phase emphasizes practical
              application, teaching participants how to leverage blockchain
              tools and services to solve real-world problems. Participants are
              encouraged to identify challenges within their communities that
              blockchain can address, promoting the development of innovative
              solutions.
            </p>
          </div>
          <div className="flow">
            <h3>Phase 3: Incubation and Business Development</h3>
            <p>
              In the final phase, selected participants with viable blockchain
              solutions are given the opportunity to enter the Incubator
              Program. This phase supports aspiring entrepreneurs by offering a
              range of business development services, from management training
              to securing investment and venture capital. The goal is to turn
              innovative blockchain ideas into fully-fledged businesses that can
              drive technological and economic growth.
            </p>
          </div>
        </article>
        <article className="wrapper__Article flow">
          {/* <div className="title__case-card">
            <h2 className="heading__case-card">1MB Inspired Campaigns</h2>
          </div> */}

          {/* Card 1 */}
          <div className="row-case-card__Article">
            {/* <div className="case-card__Article">
              <img
                src="/images/projects/celo.svg"
                alt="TTT21"
                className="Image__case-card"
              />
              <h4 className="topHeading__case-card title__top">Case Study</h4>
              <h3 className="heading__case-card">TTT 2021</h3>
              <p className="description__case-card">
                UABA and Celo partnered to conduct Train-the-Train 2021 (TTT21)
                to educate people in Africa on blockchain technology and the
                Celo ecosystem.
              </p>
              <a href="/celo" className="smallAction interface cta__case-card">
                Learn More
              </a>
            </div> */}
            {/* Card 2 */}
            {/* <div className="case-card__Article">
              <img
                src="/images/projects/wit2023.svg"
                alt="TTT21"
                className="Image__case-card"
              />
              <h4 className="topHeading__case-card title__top">Case Study</h4>
              <h3 className="heading__case-card">WIT Blockchain Program</h3>
              <p className="description__case-card">
                UABA Women In Tech (WIT) partnered to conduct to women who were
                to train fellow women in 5 countries in Africa on Blockchain
                Technology and its uses.
              </p>
              <a href="/" className="smallAction interface cta__case-card">
                Learn More
              </a>
            </div> */}
          </div>
        </article>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default OneMb;
