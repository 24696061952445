import "./membership.css";

const Membership = () => {
  return (
    <div className="container__Membership">
      <img
        src="/images/bgs/membership.svg"
        alt="Abstract technology particle gray background by Harryarts"
        className="BgImage__Membership"
      />
      <div className="row__Membership">
        <div className="left__Membership">
          <span className="topHeading__Membership title__top">
            Get Involved
          </span>
          <span className="heading__Membership title__section">
            Be Part of the Change
          </span>
          <span className="description">
            From volunteering to membership, there are many ways you can
            contribute to Africa’s blockchain transformation. Get involved today
            and help us build a brighter future.
          </span>
          <a
            href="/participate"
            className="button ActionOne interface"
            target="_blank"
            rel="noopener noreferrer"
          >
            JOIN US
          </a>
        </div>
        <div className="right__Membership shadow1">
          <span className="heading_right">Why Join Us</span>
          <ul>
            <li>Access to industry-leading events and conferences</li>
            <li>Cutting-edge blockchain training and education programs</li>
            <li>
              Networking with top innovators and decision-makers across Africa
            </li>
            <li>
              Influence and participate in strategic initiatives shaping
              Africa’s blockchain landscape
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Membership;
