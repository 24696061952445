import "./teams.css";

const Teams = () => {
  return (
    <div className="container__Teams">
      <div className="row__Teams">
        <div className="top__Teams">
          <h3 className="topHeading title__top">OUR TEAM</h3>
          <h2 className="heading__Teams title3">The People Behind UABA</h2>
          <p className="description__Teams">
            Our team is composed of passionate professionals and thought leaders
            dedicated to advancing blockchain technology across Africa.
            Together, we bring diverse expertise, united by a shared vision of
            driving innovation and sustainable development.
          </p>
        </div>
        {/* uaba team  */}
        <div className="bottom__Teams">
          {/* Profile 1 */}
          <div className="profile__Teams shadow">
            <img
              src="/images/team/yaliwe.jpg"
              alt=""
              className="Image--profile__Teams"
            />
            <div className="text--profile__Teams">
              <a
                href="https://www.linkedin.com/in/yaliwe-soko-essence/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="heading--profile__Teams">Yaliwe Mlambo</h4>
                <p className="position--profile__Teams">Chairperson</p>
              </a>
            </div>
          </div>
          {/* Profile 2 */}
          <div className="profile__Teams shadow">
            <img
              src="/images/team/anita.jpg"
              alt=""
              className="Image--profile__Teams"
            />
            <div className="text--profile__Teams">
              <a
                href="https://www.linkedin.com/in/anita-cuchamano-33a10827/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="heading--profile__Teams">Anita Mukuchamano</h4>
                <p className="position--profile__Teams">
                  Global Head of Operations
                </p>
              </a>
            </div>
          </div>
          {/* Profile 3 */}
          <div className="profile__Teams shadow">
            <img
              src="/images/team/larry.jpg"
              alt=""
              className="Image--profile__Teams"
            />
            <div className="text--profile__Teams">
              <a
                href="https://www.linkedin.com/in/larry-mwansa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="heading--profile__Teams">Larry Mwansa</h4>
                <p className="position--profile__Teams">
                  Head Of Communications
                </p>
              </a>
            </div>
          </div>
        </div>
        {/* Advisors  */}
        <hr className="divider__Teams" />
        <h2 className="heading-advisors">Our Advisors</h2>
        <div className="end__Teams">
          {/* Advisor Profile 1 */}
          <div className="profile__Teams shadow">
            <img
              src="/images/team/caroline.jpg"
              alt=""
              className="Image--profile__Teams"
            />
            <div className="text--profile__Teams">
              <a
                href="https://www.linkedin.com/in/caroline-mukiira/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="heading--profile__Teams">Caroline Mukiira</h4>
                <p className="position--profile__Teams">
                  New Client Acquisition & Product Led Growth Leader -IBM Middle
                  East,Africa & Turkiye
                </p>
              </a>
            </div>
          </div>
          {/* Advisor Profile 2 */}
          <div className="profile__Teams shadow">
            <img
              src="/images/team/fred.jpg"
              alt=""
              className="Image--profile__Teams"
            />
            <div className="text--profile__Teams">
              <a
                href="https://www.linkedin.com/in/fred-stawitz-17a8427/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="heading--profile__Teams">Fred Stawitz</h4>
                <p className="position--profile__Teams">
                  President, Storymakers
                </p>
              </a>
            </div>
          </div>
          {/* Advisor Profile 3 */}
          <div className="profile__Teams shadow">
            <img
              src="/images/team/sindhu.jpg"
              alt=""
              className="Image--profile__Teams"
            />
            <div className="text--profile__Teams">
              <a
                href="https://www.linkedin.com/in/dr-sindhu-bhaskar-55a84568/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="heading--profile__Teams">Dr. Sindhu Bhaskar</h4>
                <p className="position--profile__Teams">Chairman EST Group</p>
              </a>
            </div>
          </div>
          {/* Advisor Profile 4 */}
          <div className="profile__Teams shadow">
            <img
              src="/images/team/jordan.jpg"
              alt=""
              className="Image--profile__Teams"
            />
            <div className="text--profile__Teams">
              <a
                href="https://www.linkedin.com/in/j-tokens/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="heading--profile__Teams">Jordan Gitterman</h4>
                <p className="position--profile__Teams">Founder at Item Banc</p>
              </a>
            </div>
          </div>
          {/* Advisor Profile 4 */}
          <div className="profile__Teams shadow">
            <img
              src="/images/team/wale.jpg"
              alt=""
              className="Image--profile__Teams"
            />
            <div className="text--profile__Teams">
              <a
                href="https://www.linkedin.com/in/olawale-osborne-oderinde-8607a113/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="heading--profile__Teams">
                  Olawale Osborne Oderinde
                </h4>
                <p className="position--profile__Teams">
                  Entrepreneur, Leadership Coach
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
