import './resources.css'

const Resources = () => {
  return (
    <div className="container__Resources">
      <img
        src="/images/bgs/resources.svg"
        alt="White background with hexagonal line pattern design by Starline"
        className="BgImage__Resources"
      />
      <div className="row__Resources">
        <div id="let_item" className="left_column">
          {/* Card 1 */}
          <div className="left_item shadow1">
            <span className="heading_item title__top">BLOG</span>
            <span className="text_item">
              Explore insights, thought leadership, and the latest updates from
              the world of blockchain through our blog. We cover key trends,
              project updates, and educational content for all levels.
            </span>
          </div>
          {/* Card 2 */}
          <div className="left_item shadow1">
            <span className="heading_item title__top">KNOWLEDGE HUB</span>
            <span className="text_item">
              Download whitepapers, research reports, and case studies
              showcasing the power of blockchain technology in Africa. This
              resource library is designed to educate and inspire.
            </span>
          </div>
          {/* Card 2 */}
          <div className="left_item shadow1">
            <span className="heading_item title__top">FAQs</span>
            <span className="text_item">
              New to blockchain? Check out our FAQ section for answers to common
              questions about blockchain technology, UABA’s work, and how you
              can get involved.
            </span>
          </div>
        </div>
        <div className="right_column">
          <span className="topheading__Resources title__top">
            RESOURCE CENTER
          </span>
          <span className="heading__Resources title__section">
            Stay Informed, Stay Ahead
          </span>
          <span className="description">
            Dive into our resource hub for the latest news, tutorials, and
            research on blockchain, empowering you with the knowledge to lead in
            the digital age.
          </span>
          <a
            href="/hub"
            className=" cta__resources button ActionOne interface shadow1"
            target="_blank"
            rel="noopener noreferrer"
          >
            EXPLORE NOW
          </a>
        </div>
      </div>
    </div>
  );
}

export default Resources