import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "./main.css";
import Home from "./pages/home/Home";
import Academy from "./pages/academy/Academy";
import AboutPage from "./pages/about/AboutPage";
import OneMb from "./pages/initiativesPg/OneMb";
import Ttt from "./pages/initiativesPg/Ttt";
import Learn from "./pages/resourcesPgs/Learn";
import Celo from "./pages/projects/Celo";
import Techcon from "./pages/initiativesPg/Techcon";
import Participate from "./pages/resourcesPgs/Participate";
import Hub from "./pages/resourcesPgs/Hub";
import Blog from "./pages/blog/Blog";
import FormContact from "./pages/contactForm/ContactForm";

const Layout = () => {
  return (
    <div className="app">
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/academy",
        element: <Academy />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/celo",
        element: <Celo />,
      },
      {
        path: "/contact",
        element: <FormContact />,
      },
      {
        path: "/hub",
        element: <Hub />,
      },
      {
        path: "/learn",
        element: <Learn />,
      },
      {
        path: "/participate",
        element: <Participate />,
      },
      {
        path: "/omb",
        element: <OneMb />,
      },
      {
        path: "/techcon",
        element: <Techcon />,
      },
      {
        path: "/ttt",
        element: <Ttt />,
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
