import './events.css'

const Events = () => {
  return (
    <div className="container__Events">
      {/*Heading */}
      <div className="title__Events">
        <span className="topHeading__Events title__top">NEWS & EVENTS</span>
        <span className="heading__Events title__section">
          Engage, Learn, and Lead
        </span>
        <p className="description_Events description">
          Our events bring together the brightest minds in blockchain, providing
          a platform for learning, collaboration, and creating impactful
          solutions.
        </p>
      </div>
      {/* Cards */}
      <div className="cards__Events">
        {/* Card1 */}
        <div className="card_item shadow1">
          <img src="/images/events/amc.jpg" alt="AMC Conference 2024" className="item_Image" />
          <span className="item_category">EVENTS</span>
          <span className="item_heading">
            The Inaugural Africa Music Conference
          </span>
          <p className="item_description description">
            Join us for the 10th edition of the Blockchain Africa Conference,
            hosted by Bitcoin Events. This highly anticipated event will take
            place as an in-person experience at the CSIR International
            Convention Centre, on 20 November 2024, in Tshwane, South Africa.
          </p>
          <a
            href="https://amc.cx/"
            target="_blank"
            rel="noopener noreferrer"
            className="smallAction interface"
          >
            Learn More
          </a>
        </div>

        {/* Card2 */}
        <div className="card_item shadow1">
          <img
            src="/images/events/techcontz.jpg"
            alt=""
            className="item_Image"
          />
          <span className="item_category">Events</span>
          <span className="item_heading">TechCon Tanzania 2024</span>
          <p className="item_description">
            TechCon TZ brought together industry leaders, innovators, and
            government officials to explore blockchain and emerging
            technologies. Special thanks to our partners, the E-Government
            Authority of Tanzania for hosting us, and Tether for their support
            in making this event possible.
          </p>
          <a
            href="/hub"
            className="smallAction interface"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </div>

        {/* Card3 */}
        <div className="card_item shadow1">
          <img
            src="/images/events/techcon.jpg"
            alt="TechCon Government Cape Town"
            className="item_Image"
          />
          <span className="item_category">EVENTS</span>
          <span className="item_heading">TechCon Government Edition 2024</span>
          <p className="item_description">
            The TechCon Government Edition brought together government leaders
            and experts to explore the impact of cutting-edge technologies on
            governance. It provided officials with essential knowledge and tools
            to harness emerging technologies.
          </p>
          <a
            href="hub"
            className="smallAction interface"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
}

export default Events