import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import "./resources.css";

const Participate = () => {
  return (
    <>
      <nav>
        <Navbar />
      </nav>
      <header className="header">
        <div className="container-header__Participate">
          <div className="row-header__Participate shadow">
            <div className="header__Participate">
              <h3 className="topheading__Participate title__top">
                PARTICIPATE
              </h3>
              <h1 className="heading__Overview title3">Get Involved</h1>
              <p className="description__Participate">
                By joining UABA, you become part of Africa’s leading blockchain
                community, with access to exclusive events, educational
                programs, and strategic networking opportunities. Our membership
                offers individuals, startups, and corporations the chance to
                shape Africa’s blockchain future.
              </p>
              <a
                href="#overview"
                className="button__Participate button ActionOne interface shadow1"
              >
                GET STARTED
              </a>
            </div>
          </div>
        </div>
      </header>
      <article className="sticky stickyStuff"></article>
      <main>
        <div className="container__sidebar">
          <div className="left__sidebar">
            <div className="sideMenu">
              <h2 className="heading__sideMenu title__top">ON THIS PAGE</h2>
              <div className="Link__sideMenu">
                <a href="#overview">Introduction</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#membership">Membership</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#volunteer">Volunteer</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#donate">Donate</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#partnership">Partnership</a>
              </div>
            </div>
          </div>
          <div className="right__sidebar flow">
            <h2 id="overview">
              How You Can Contribute to Africa’s Blockchain Future
            </h2>
            <p>
              Welcome to the United Africa Blockchain Association (UABA)
              community, where individuals, organizations, and institutions come
              together to make a real impact on Africa's blockchain landscape.
              Whether you're looking to join our network, volunteer your skills,
              or partner with us, UABA offers a range of opportunities to get
              involved.
            </p>
            <div className="features__Participate">
              {/* Card 1 */}
              <div id="membership" className="card__Participate">
                <img src="/images/resources/membership.jpg" alt="" />
                <h3>Membership: Be Part of Africa’s Blockchain Movement</h3>
                <p>
                  UABA’s membership program is designed for innovators,
                  professionals, startups, and corporations eager to shape
                  Africa’s future through blockchain technology. By becoming a
                  member, you gain exclusive access to:
                </p>
                <ul>
                  <li>Invitations to private events and workshops.</li>
                  <li>
                    Educational programs to expand your blockchain expertise.
                  </li>
                  <li>
                    Networking opportunities with industry leaders and
                    blockchain experts.
                  </li>
                </ul>
                <h4>Key Benefits of Membership</h4>
                <ul>
                  <li>
                    <strong>Access to Cutting-Edge Resources</strong>: Stay
                    ahead with the latest trends and developments.
                  </li>
                  <li>
                    <strong>Industry Networking</strong>: Collaborate with key
                    stakeholders across Africa’s blockchain ecosystem.
                  </li>
                  <li>
                    <strong>Shape the Future</strong>: Engage with thought
                    leaders and influence the direction of blockchain adoption.
                  </li>
                </ul>
                <a
                  href="/contact"
                  className="button-card__hub button ActionOne interface shadow1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CONTACT US
                </a>
              </div>
              {/* Card 2 */}
              <div id="volunteer" className="card__Participate">
                <img id="hub" src="/images/resources/volunteer.jpg" alt="" />
                <h3>Volunteer: Make a Difference in Your Community</h3>
                <p>
                  Contribute to UABA’s mission by volunteering your time,
                  knowledge, and skills. We’re looking for passionate
                  individuals who can:
                </p>
                <ul>
                  <li>Organize or host local blockchain awareness events.</li>
                  <li>Assist in educational campaigns or workshops.</li>
                  <li>
                    Provide expertise on blockchain projects and initiatives.
                  </li>
                </ul>
                <h4>Opportunities to Volunteer:</h4>
                <ul>
                  <li>
                    <strong>Community Leaders</strong>: Spread blockchain
                    awareness in your local region.
                  </li>
                  <li>
                    <strong>Educational Support</strong>: Help organize
                    blockchain literacy programs for youth and professionals.
                  </li>
                  <li>
                    <strong>Technical Experts</strong>: Share your blockchain
                    development skills on various initiatives.adoption.
                  </li>
                </ul>
                <a
                  href="/contact"
                  className="button-card__hub button ActionOne interface shadow1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CONTACT US
                </a>
              </div>
              {/* Card 3 */}
              <div id="donate" className="card__Participate">
                <img src="/images/resources/donate.jpg" alt="" />
                <h3>Donate: Support Blockchain Education and Innovation</h3>
                <p>
                  Your contributions directly help us expand blockchain
                  education, support underrepresented communities, and develop
                  innovative solutions for Africa’s unique challenges. By
                  donating, you are:
                </p>
                <ul>
                  <li>
                    Supporting blockchain literacy initiatives that reach remote
                    communities.
                  </li>
                  <li>
                    Helping us fund educational workshops, mentorship programs,
                    and advocacy work.
                  </li>
                  <li>Empowering future blockchain leaders across Africa.</li>
                </ul>
                <h4>Where Your Donations Go:</h4>
                <ul>
                  <li>
                    <strong>Blockchain Literacy</strong>: Funding for workshops,
                    webinars, and educational materials.
                  </li>
                  <li>
                    <strong>Community Development</strong>:Supporting the launch
                    of blockchain initiatives for economic empowerment.
                  </li>
                  <li>
                    <strong>Technology Access</strong>: Ensuring that
                    underserved communities have access to blockchain tools and
                    platforms.
                  </li>
                </ul>
                <a
                  href="/contact"
                  className="button-card__hub button ActionOne interface shadow1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CONTACT US
                </a>
              </div>
              {/* Card 4 */}
              <div id="partnership" className="card__Participate">
                <img src="/images/resources/partnership.jpg" alt="" />
                <h3>
                  Partnerships & Collaborations: Work with UABA to Shape the
                  Future
                </h3>
                <p>
                  UABA works closely with government agencies, tech companies,
                  and international organizations to create blockchain-driven
                  solutions that transform communities. Our partnership programs
                  focus on:
                </p>
                <ul>
                  <li>
                    <strong> Blockchain Research & Development</strong>:
                    Collaborating on new blockchain technologies.
                  </li>
                  <li>
                    <strong> Advocacy & Policy</strong>: Working with
                    governments to shape policies that promote blockchain
                    adoption.
                  </li>
                  <li>
                    <strong> Digital Inclusion</strong>: Promoting blockchain
                    projects that uplift underrepresented communities and drive
                    economic growth.
                  </li>
                </ul>
                <h4>How We Collaborate:</h4>
                <ul>
                  <li>
                    <strong>Project Co-development</strong>: Create impactful
                    blockchain solutions.
                  </li>
                  <li>
                    <strong>Joint Initiatives</strong>: Work together on
                    blockchain advocacy, education, and policy reform.
                  </li>
                  <li>
                    <strong>Strategic Collaborations</strong>: Develop
                    blockchain frameworks for various sectors including finance,
                    supply chain, and government services.
                  </li>
                </ul>
                <a
                  href="/contact"
                  className="button-card__hub button ActionOne interface shadow1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CONTACT US
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default Participate