import "./footer.css";

const Footer = () => {
  return (
    <div className="container__footer">
      <div className="top__footer">
        <div className="items__footer">
          <h4>Overview</h4>
          <a href="/about">About</a>
          <a href="/about">Membership</a>
          <a href="/about">Initiatives</a>
        </div>
        <div className="items__footer">
          <h4>Learn</h4>
          <a href="/learn">What is Blockchain?</a>
          <a href="/learn">What is the Metaverse?</a>
          <a href="/learn">DFL Basics</a>
        </div>
        <div className="items__footer">
          <h4>News & Events</h4>
          <a href="/hub">Blog</a>
          <a href="/hub">Events</a>
          <a href="/hub">Press Office</a>
          <a href="/hub">Brand Pack</a>
        </div>
        <div className="items__footer">
          <h4>Community</h4>
          <div className="sm-icons">
            <a
              href="https://www.facebook.com/United.AfricaBA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/sm-icons/facebook.png" alt="Facebook" />
            </a>
            {/* <a href="/">
              <img src="/images/sm-icons/instagram.png" alt="Instagram" 
               target="_blank"
              rel="noopener noreferrer"
              />
            </a> */}
            <a
              href="https://www.linkedin.com/company/uaba-united-africa-blockchain-association/posts/?feedView=allfffff"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="images/sm-icons/linkedin.png" alt="LinkedIn" />
            </a>
            <a
              href="https://t.me/+bqDkYULev5xhZThk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/sm-icons/telegram.png" alt="Telegram" />
            </a>
            <a
              href="https://x.com/UABA_Africa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/sm-icons/twitter_x.png" alt="" />
            </a>
            {/* <a href="/" target="_blank" rel="noopener noreferrer">
              <img src="/images/sm-icons/github.png" alt="" />
            </a> */}
            <a
              href="https://chat.whatsapp.com/F4a8K1LSOu231qcGbXskcN"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/sm-icons/whatsapp.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="bottom__footer">
        <img src="/logo.png" alt="UABA Logo" />
        <p>
          Copyright © 2019-2024 United Africa Blockchain Association. All rights
          reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
