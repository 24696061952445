import "./intro.css";

const Intro = () => {
  return (
    <div className="container__intro custom-pattern">
      <div className="row__intro">
        <div className="left_intro">
          <span className="topHeading__intro title__top">WE ARE ABOUT</span>
          <span className="heading__intro title__section">
            Pioneering Africa’s Blockchain Future
          </span>
          <p className="text__intro description">
            The UABA story is one of growth and impact, as we connect nations
            and communities to unlock the potential of blockchain technology,
            transforming lives across Africa.
          </p>
          <a
            href="/about"
            className="button__about button__about button ActionOne interface shadow1"
            target="_blank"
            rel="noopener noreferrer"
          >
            LEARN MORE
          </a>
        </div>
        <div className="right__intro shadow">
          <img
            src="/images/objects/story.svg"
            alt="Two stacked cubes with rainbow gradient color"
            className="object__intro"
          />
        </div>
      </div>
    </div>
  );
};

export default Intro;
