import Footer from "../../components/footer/Footer";
import HeaderAbout from "../../components/hero/headerAbout";
import Navbar from "../../components/navbar/Navbar";
import Mission from "../../components/sections/Mission";
import Pillars from "../../components/sections/Pillars";
import Vision from "../../components/sections/Vision";
import WhatWeDo from "../../components/sections/WhatWeDo";
import Teams from "../../components/teams/Teams";
import "./aboutPage.css";

const AboutPage = () => {
  return (
    <div>
      <nav>
        <Navbar />
      </nav>
      <header>
        <HeaderAbout />
      </header>
      <main>
        <article>
          <WhatWeDo />
        </article>
        <article><Vision /></article>
        <article><Mission/></article>
        <article><Pillars/></article>
        <article><Teams/></article>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default AboutPage;
