import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import "./resources.css";

const Learn = () => {
  return (
    <>
      <nav>
        <Navbar />
      </nav>
      <header className="header">
        <div className="container-header__Learn">
          <div className="row-header__Learn">
            <div className="title-header__Learn shadow">
              <h3 className="topheading__Overview title__top">
                UNLOCK THE FUTURE OF TECHNOLOGY
              </h3>
              <h1 className="heading__Overview title3">
                Master Blockchain with UABA
              </h1>
              <p className="description-header__Learn">
                Embark on a transformative journey into blockchain technology.
                Whether you're a beginner or looking to enhance your expertise,
                UABA offers tailored resources and hands-on training to help you
                shape the future of Africa's digital landscape.
              </p>
              <a
                href="/academy"
                className="button__learn button ActionOne interface shadow1"
              >
                GET STARTED
              </a>
            </div>
          </div>
        </div>
      </header>
      <article className="sticky stickyStuff"></article>
      <main>
        <div className="container__sidebar">
          <div className="left__sidebar">
            <div className="sideMenu">
              <h2 className="heading__sideMenu title__top">ON THIS PAGE</h2>
              <div className="Link__sideMenu">
                <a href="#whatisB">What is Blockchain?</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#whyLearn">Why Learn Blockchain?</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#pathways">UABA Learning Pathways</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#certification">Blockchain Certification Programs</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#tools">Resources and Tools for Continuous Learning</a>
              </div>
              <div className="Link__sideMenu">
                <a href="#community">Join the Blockchain Community</a>
              </div>
            </div>
          </div>
          <div className="right__sidebar flow">
            <h2 id="overview">Start Your Journey to Blockchain Mastery</h2>
            <p>
              Blockchain technology is revolutionizing industries across the
              globe, and Africa is no exception. Whether you're new to
              blockchain or looking to expand your knowledge, the United Africa
              Blockchain Association (UABA) provides comprehensive resources,
              courses, and training programs designed to help you navigate this
              emerging technology.
            </p>
            <div className="features__Learn">
              {/* Card 1 */}
              <div id="whatisB" className="card__Participate card__Learn">
                <img
                  src="/images/resources/what-is.jpg"
                  alt="What is Blockchain"
                />
                <h3> What is Blockchain?</h3>
                <p>
                  Blockchain is a decentralized digital ledger technology that
                  allows data to be stored and shared securely without a central
                  authority. It is the foundation for cryptocurrencies like
                  Bitcoin and Ethereum, but its applications extend far beyond
                  financial services. Blockchain is transforming industries such
                  as healthcare, supply chain management, governance, and
                  digital identity verification.
                </p>
                <ul>
                  <li>
                    <strong>Decentralization:</strong> No single entity controls
                    the blockchain.
                  </li>
                  <li>
                    <strong>Security</strong> Transactions are encrypted and
                    immutable.
                  </li>
                  <li>
                    <strong>Transparency</strong>Every transaction is visible
                    and verifiable.
                  </li>
                </ul>
                <a
                  href="/academy"
                  className="button-card__hub button ActionOne interface shadow1"
                >
                  LEARN MORE HERE
                </a>
              </div>
              {/* Card 2 */}
              <div id="whyLearn" className="card__Participate card__Learn">
                <img id="hub" src="/images/resources/why.jpg" alt="Why learn" />
                <h3>Why Learn Blockchain?</h3>
                <p>
                  As blockchain technology continues to grow, understanding its
                  fundamentals can open up new career opportunities and
                  entrepreneurial ventures. By learning blockchain, you can:
                </p>
                <ul>
                  <li>
                    <strong>Enhance your career prospects</strong>: Blockchain
                    is one of the most in-demand skills globally.
                  </li>
                  <li>
                    <strong> Develop innovative solutions</strong>: Apply
                    blockchain to solve real-world challenges in Africa.
                  </li>
                  <li>
                    <strong>Become part of a global movement</strong>:
                    Contribute to the digital transformation of industries.
                  </li>
                </ul>
                <h4>Who Should Learn Blockchain?</h4>
                <ul>
                  <li>Tech enthusiasts, developers, and entrepreneurs.</li>
                  <li>
                    Individuals interested in cryptocurrency and digital assets.
                  </li>
                  <li>
                    Professionals in sectors like finance, law, healthcare, and
                    supply chain management.
                  </li>
                </ul>
                <a
                  href="/academy"
                  className="button-card__hub button ActionOne interface shadow1"
                >
                 DISCOVER WHY HERE
                </a>
              </div>
              {/* Card 3 */}
              <div id="pathways" className="card__Participate card__Learn">
                <img src="/images/resources/pathways.jpg" alt="Pathways" />
                <h3>
                  UABA Learning Pathways: Your Gateway to Blockchain Expertise
                </h3>
                <p>
                  At UABA, we offer structured learning pathways for
                  everyone—from beginners to experts—designed to help you build
                  a strong foundation and advance your blockchain knowledge.
                </p>
                <h4>Beginner Courses:</h4>
                <ul>
                  <li>
                    <strong>Introduction to Blockchain Technology</strong>:
                    Learn the basics of how blockchain works and why it matters.
                  </li>
                  <li>
                    <strong>Blockchain for Non-Techies</strong>: Understand
                    blockchain concepts without needing technical expertise.
                  </li>
                </ul>
                <h4>Intermediate Courses:</h4>
                <ul>
                  <li>
                    <strong>Blockchain Development</strong>: Dive into coding
                    for blockchain platforms such as Ethereum and Hyperledger.
                  </li>
                  <li>
                    <strong>Smart Contracts 101</strong>:Learn to create
                    self-executing contracts using blockchain.
                  </li>
                </ul>
                <h4>Advanced Courses:</h4>
                <ul>
                  <li>
                    <strong>Blockchain for Enterprise Solutions</strong>:
                    Explore how blockchain is applied in industries like
                    finance, supply chain, and governance.
                  </li>
                  <li>
                    <strong>Blockchain Security</strong>:Supporting the
                    Understand how to secure blockchain networks and protect
                    digital assets.
                  </li>
                </ul>
                <a
                  href="/academy"
                  className="button-card__hub button ActionOne interface shadow1"
                >
                  FIND THE RIGHT COURSE HERE
                </a>
              </div>
              {/* Card 4 */}
              <div id="certification" className="card__Participate card__Learn">
                <img
                  src="/images/resources/certification.jpg"
                  alt="Certificate Programs"
                />
                <h3>Blockchain Certification Programs</h3>
                <p>
                  Our certification programs offer an in-depth understanding of
                  blockchain technology and its real-world applications. Each
                  course is designed by experts and provides practical
                  knowledge, hands-on experience, and a globally recognized
                  certification.
                </p>
                <h4>Why Get Certified?</h4>
                <ul>
                  <li>
                    <strong>Industry Recognition: </strong>: Stand out in the
                    job market with a blockchain certification.
                  </li>
                  <li>
                    <strong>Hands-On Experience</strong>: Gain real-world
                    blockchain skills by working on live projects.
                  </li>
                  <li>
                    <strong>Career Advancement</strong>: Open up opportunities
                    for blockchain roles in leading industries.
                  </li>
                </ul>
                <h4>Available Certifications:</h4>
                <ul>
                  <li>Certified Blockchain Professional (CBP)</li>
                  <li>Certified Smart Contract Developer (CSCD)</li>
                  <li>Certified Blockchain Architect (CBA)</li>
                </ul>
                <a
                  href="/academy"
                  className="button-card__hub button ActionOne interface shadow1"
                >
                  LEARN MORE HERE
                </a>
              </div>
              {/* Card 5 */}
              <div id="tools" className="card__Participate card__Learn">
                <img
                  src="/images/resources/tools.jpg"
                  alt="Learning Resources"
                />
                <h3>Resources and Tools for Continuous Learning</h3>
                <p>
                  Beyond courses, UABA offers a rich library of blockchain
                  resources to support your learning journey. From tutorials and
                  webinars to research papers and case studies, you'll have
                  access to the latest insights from the blockchain world.
                </p>
                <h4>Available Resources:</h4>
                <ul>
                  <li>
                    <strong>Blockchain Webinars</strong>: Stay updated with live
                    webinars on trending topics in blockchain.
                  </li>
                  <li>
                    <strong>Case Studies</strong>: Explore how blockchain is
                    solving real-world problems in Africa and beyond.
                  </li>
                  <li>
                    <strong>Research Papers</strong>: Deepen your knowledge with
                    blockchain research and whitepapers.
                  </li>
                </ul>
                <a
                  href="/resources"
                  className="button-card__hub button ActionOne interface shadow1"
                >
                  BROWSE OUR RESOURCE HUB HERE
                </a>
              </div>
              {/* Card 6 */}
              <div id="community" className="card__Participate card__Learn">
                <img
                  src="/images/resources/join.jpg"
                  alt="Join the Community"
                />
                <h3>Join the Blockchain Community</h3>
                <p>
                  Blockchain is more than just technology—it’s a global
                  community. UABA provides networking opportunities through
                  events, hackathons, and mentorship programs. Connect with
                  fellow learners, industry experts, and innovators to expand
                  your knowledge and explore blockchain’s potential together.
                </p>
                <h4>Ways to Get Involved:</h4>
                <ul>
                  <li>
                    <strong>Attend Blockchain Events</strong>: Join local
                    meetups, workshops, and conferences.
                  </li>
                  <li>
                    <strong>Participate in Hackathons</strong>: Build blockchain
                    projects in collaborative environments.
                  </li>
                  <li>
                    <strong>Mentorship Programs</strong>: Get guidance from
                    experienced professionals in the blockchain space.
                  </li>
                </ul>
                <a
                  href="/participate"
                  className="button-card__hub button ActionOne interface shadow1"
                >
                  LEARN MORE HERE
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default Learn