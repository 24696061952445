import "./vision.css";

const Vision = () => {
  return (
    <div className="container__Vision">
      <div className="row__Vision">
        <div className="left__Vision">
          <h3 className="topHeading__Vision title__top">OUR VISION</h3>
          <h2 className="heading__Vision title3">
            A Vision for Africa’s Digital Revolution
          </h2>
          <p className="description__Vision">
            To promote the advancement of emerging digital technology in Africa
            by creating partnerships and networks that drive life-changing
            blockchain adoption and project deployment.
          </p>
        </div>
        <div className="right__Vision">
          <img
            src="/images/objects/vision.svg"
            alt="A 3D Abstract Shape"
            className="rightImage__Vision"
          />
        </div>
      </div>
    </div>
  );
};

export default Vision;
