import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import './blog.css';

const Blog = () => {
  return (
    <div className="container__Blog">
        <nav>
            <Navbar/>
        </nav>
      <div className="header-container__Blog">
        <div className="row-header__Blog">
          <div className="title-header__Blog">
            <h3 className="topheading-header__Blog title__top">CATEGORY</h3>
            <h1 className="heading-header__Blog title3">Hero Title</h1>
            <p className="descrption-header__Blog">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Cupiditate temporibus pariatur beatae perspiciatis, deleniti
              quidem maiores fuga eligendi suscipit totam sapiente, aliquid
              quaerat consequatur est delectus, aliquam modi voluptatem neque.
            </p>
          </div>
          <div className="meta-header__Blog">
            <h4>Author's Name</h4>
            <span className="date">21 October 2024</span>
          </div>
        </div>
        <div className="pageInfo">
            <h2 className="pageTitle">Page Title</h2>
            <p className="pageSubtitle">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </div>
      </div>
      <footer>
        <Footer/>
      </footer>
    </div>
  );
};

export default Blog;
