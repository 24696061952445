import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./projects.css";

const Celo = () => {
  return (
    <div className="container__CaseStudy">
      <nav>
        <Navbar />
      </nav>
      <header className="container-Header__CaseStudy">
        <div className="row-Header__CaseStudy">
          <div className="heading-Header__CaseStudy">
            <h3 className="topHeading-Header__CaseStudy title__top">
              UABA-CELO PROJECT
            </h3>
            <h2 className="title-Header__CaseStudy">Blockchain Course</h2>
            <h4 className="bottomHeading-Header__CaseStudy">
              TTT2021 ONLINE TRAINING PROGRAM
            </h4>
          </div>

          <div className="Image-Header__CaseStudy">
            <img
              src="/images/projects/headerttt21.jpg"
              className="shadow1"
              alt="Visuals by David Awokoya at Pexels"
            />
          </div>
        </div>
      </header>
      <main>
        {/* Strategy Section */}
        <article className="Overview__CaseStudy">
          <div className="row-Overview__CaseStudy">
            <div className="left-overview__CaseStudy">
              {" "}
              <h3 className="topHeading-overview__CaseStudy title__top">
                Overview
              </h3>
              <h2 className="heading-overview__CaseStudy">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              </h2>
            </div>
            <div className="right-overview__CaseStudy">
              <p className="lead-overview__CaseStudy">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
                tenetur quidem quaerat, in rem porro sequi cumque vero et ipsa
                vitae expedita ex! Fugiat ab a sit aut. At, nulla.
              </p>
              <h2 className="head-description-overview">
                Hic itaque deleniti sed repellendus
              </h2>
              <p className="description-overview__CaseStudy">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. In
                minima iusto culpa architecto suscipit, velit reprehenderit
                praesentium harum, illum, quod magnam molestiae explicabo
                repudiandae sequi consequatur numquam maxime consectetur
                tempore?
              </p>
            </div>
          </div>
        </article>
        <hr />
        {/* Strategy Section */}
        <article className="container__Strategy">
          <div className="row-Overview__CaseStudy">
            <div className="left-overview__CaseStudy">
              {" "}
              <h3 className="topHeading-overview__CaseStudy title__top">
                Strategy
              </h3>
              <h2 className="heading-overview__CaseStudy">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              </h2>
            </div>
            <div className="right-overview__CaseStudy">
              <p className="lead-overview__CaseStudy">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
                tenetur quidem quaerat, in rem porro sequi cumque vero et ipsa
                vitae expedita ex! Fugiat ab a sit aut. At, nulla.
              </p>
              <h2 className="head-description-overview">
                Hic itaque deleniti sed repellendus
              </h2>
              <p className="description-overview__CaseStudy">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. In
                minima iusto culpa architecto suscipit, velit reprehenderit
                praesentium harum, illum, quod magnam molestiae explicabo
                repudiandae sequi consequatur numquam maxime consectetur
                tempore?
              </p>
            </div>
          </div>
        </article>
        {/* Galley Section */}
        <div className="container-gallery__CaseStudy">
          <div className="row-gallery__CaseStudy">
            <img
              src="./images/projects/case11.jpg"
              alt=""
              className="Image-gallery__CaseStudy"
            />
            <img
              src="/images/projects/case2.jpg"
              alt=""
              className="Image-gallery__CaseStudy"
            />
            <img
              src="/images/projects/case3.jpg"
              alt=""
              className="Image-gallery__CaseStudy"
            />
            <img
              src="/images/projects/case44.jpg"
              alt=""
              className="Image-gallery__CaseStudy"
            />
          </div>
        </div>
        {/* Result Section */}
        <article className="container__Result">
          <div className="row-Overview__CaseStudy">
            <div className="left-overview__CaseStudy">
              <h3 className="topHeading-overview__CaseStudy title__top">
                Result
              </h3>
              <h2 className="heading-overview__CaseStudy">
                Lorem ipsum dolor sit,
                <br />
                amet consectetur adipisicing elit.
              </h2>
            </div>
            <div className="right-overview__CaseStudy">
              <p className="lead-overview__CaseStudy">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
                tenetur quidem quaerat, in rem porro sequi cumque vero et ipsa
                vitae expedita ex! Fugiat ab a sit aut.
              </p>
              {/* Numbers */}
              <div className="numbers-Result__CaseStudy">
                <div>
                  <span className="digits-Result__CaseStudy">67%</span>
                  <span className="digit-text">Lorem ipsum dolor sit amet</span>
                </div>
                <div>
                  <span className="digits-Result__CaseStudy">4.2m</span>
                  <span className="digit-text">Lorem ipsum dolor sit amet</span>
                </div>
                <div>
                  <span className="digits-Result__CaseStudy">33%</span>
                  <span className="digit-text">Lorem ipsum dolor sit amet</span>
                </div>
                <div>
                  <span className="digits-Result__CaseStudy">$12m</span>
                  <span className="digit-text">Lorem ipsum dolor sit amet</span>
                </div>
              </div>
            </div>
          </div>
        </article>
        <hr />
        {/* Testimonial Section */}
        <article className="container__Testimonials">
          <div className="row__Testimonials">
            <h3 className="topHeading-testimonials__CaseStudy title__top">
              Testimonials
            </h3>
            <p className="description-testimonials">
              “The classes have been really good. I've learned more than I
              thought Blockchain was and the notes given outside of class are a
              good reference for later”
            </p>
            <p>- Sasha Annamercy</p>
          </div>
        </article>
        <hr />
        <article className="container__MoreCaseStudies">
          <div className="row__MoreCaseStudies">
            <div className="Heading__MoreCaseStudies">
              <h2 className="title__MoreCaseStudies">More Case Studies</h2>
            </div>
            <div className="cards__MoreCaseStudies">
              {/* Card 1 */}
              <div className="card-More">
                <img
                  src="./images/projects/case11.jpg"
                  alt=""
                  className="Image card-More"
                />
                <h3 className="topHeading__care-More">Case Study</h3>
                <h2 className="heading__care-More">Case Study Title</h2>
                <p className="description__care-More">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Vitae, illum nemo. Lorem ipsum dolor sit, amet consectetur
                  adipisicing elit. Vitae, illum nemo.
                </p>
                <a
                  href="/celo"
                  className="smallAction interface cta__case-card"
                >
                  Learn More
                </a>
              </div>
              {/* Card 1 */}
              <div className="card-More">
                <img
                  src="./images/projects/case11.jpg"
                  alt=""
                  className="Image card-More"
                />
                <h3 className="topHeading__care-More">Case Study</h3>
                <h2 className="heading__care-More">Case Study Title</h2>
                <p className="description__care-More">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Vitae, illum nemo. Lorem ipsum dolor sit, amet consectetur
                  adipisicing elit. Vitae, illum nemo.
                </p>
                <a
                  href="/celo"
                  className="smallAction interface cta__case-card"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </article>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Celo;
