import "./whatWeDo.css";

const WhatWeDo = () => {
  return (
    <div className="container__WhatWeDo">
        <div className="row__WhatWeDo">
      <div className="left__WhatWeDo">
        <img
          src="/images/objects/do.svg"
          alt="A 3D Polygon"
          className="leftImage"
        />
      </div>
      <div className="right__WhatWeDo">
        <h3 className="topHeading__WhatWeDo title__top">OUR IMPACT</h3>
        <h2 className="heading__WhatWeDo title3">
          Driving Change Through Blockchain Solutions
        </h2>
        <p className="description__WhatWeDo">
          UABA brings together blockchain experts, educators, and policymakers
          to drive solutions that uplift communities and strengthen local
          economies. Our work spans across Botswana, Cameroon, Kenya, Ghana,
          Nigeria, South Africa, and more, aiming to build a blockchain
          ecosystem that embodies the values of Ubuntu (humanity)
        </p>
      </div>

        </div>
    </div>
  );
};

export default WhatWeDo;
