import "./header.css";

const Header = () => {
  return (
    <div className="container__header">
      <img
        className="bgImage__header"
        src="/images/bgs/header.svg"
        alt="Abstract Background"
      />
      <div className="heading__header">
        <h1 className="title">Building Africa's Digital Revolution</h1>
        <p className="description__header subtitle__description">
          Connecting innovators, governments, and businesses to harness the
          power of blockchain for a better, more inclusive future.
        </p>
        <div className="bottom__header interface">
          <a
            href="/participate"
            className="button ActionOne shadow1"
            target="_blank"
            rel="noopener noreferrer"
          >
            JOIN US
          </a>
          <a
            href="/#news"
            className="button ActionTwo shadow1"
            rel="noopener noreferrer"
          >
            UPCOMING EVENTS
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
