import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./initiativesPg.css";

const Techcon = () => {
  return (
    <>
      <nav>
        <Navbar />
      </nav>
      <header className="container-header__Article techcon-header">
        <div className="row-header__Article wrapper__Article">
          <div className="header__Article wrapper__Article">
            <h3 className="topHeading__Article title__top">INITATIVES</h3>
            <h2 className="heading__Article title3">TechCon</h2>
          </div>
        </div>
      </header>
      <main className="main__Article">
        <article className="wrapper__Article flow">
          <p className="lead__techcon">
            TechCon is Africa’s premier multi-platform technology conference
            that brings together thought leaders, innovators, and stakeholders
            from various industries, with a special focus on blockchain
            technology. This dynamic event explores the latest advancements in
            technology and their transformative impact on sectors such as
            government, finance, education, and business. With multiple editions
            catering to different audiences, TechCon is designed to foster
            cross-industry collaboration, spark innovation, and showcase
            real-world applications of emerging technologies in Africa.
          </p>
          <div className="flow">
            <h3>Main Conference: The Pulse of Technological Innovation</h3>
            <p>
              The Main Edition of TechCon is the central event where Africa's
              most forward-thinking minds converge to discuss the future of
              technology. This edition covers a broad spectrum of topics, from
              blockchain to artificial intelligence, fintech, and beyond.
              Participants from the public and private sectors, startups,
              academia, and civil society come together to share insights,
              network, and discover how emerging technologies can solve some of
              Africa’s most pressing challenges.
            </p>
            <h4>Key Highlights:</h4>
            <ul className="flow">
              <li>
                <strong> Blockchain for Business</strong>: Learn how blockchain
                technology is disrupting traditional industries, from banking
                and finance to supply chain and agriculture. Explore case
                studies of African companies successfully implementing
                blockchain and how it’s creating new business models and
                opportunities.
              </li>
              <li>
                <strong> AI and Automation</strong>: Dive into the world of
                artificial intelligence and automation, exploring how African
                businesses can leverage these technologies to improve
                efficiency, enhance decision-making, and drive economic growth.
              </li>
              <li>
                <strong>Tech for Social Impact</strong> : Discover how startups
                and nonprofits are using technology to address social issues,
                from improving healthcare access to creating education platforms
                and fostering environmental sustainability.
              </li>
            </ul>
          </div>
          <div className="flow">
            <h3>
              Government Edition: Revolutionizing Governance Through Technology
            </h3>
            <p>
              The Government Edition of TechCon is specifically designed for
              public sector leaders, policy makers, and government officials
              across Africa. This unique edition focuses on how emerging
              technologies—particularly blockchain—can improve transparency,
              efficiency, and citizen services. Through in-depth workshops and
              interactive demonstrations, government representatives will learn
              how to harness these technologies to revolutionize governance in
              their countries.
            </p>
            <h4>Key Focus Areas:</h4>
            <ol className="flow">
              <li>
                <strong>Blockchain for Government Services</strong>: Explore how
                blockchain technology can improve secure document verification,
                decentralized identity management, and transparent procurement
                processes.
              </li>
              <li>
                <strong>Digital Governance Transformation</strong>: Learn how
                digital platforms can streamline government services, enhancing
                transparency and reducing bureaucracy.
              </li>
              <li>
                <strong>Case Studies & Global Insights</strong>: Examine
                successful case studies from governments around the world that
                have implemented blockchain to enhance public service delivery.
              </li>
            </ol>
          </div>
          <div className="flow">
            <h3>Innovative Learning Formats: Hands-on and Immersive</h3>
            <p>
              TechCon goes beyond traditional conferences, offering attendees
              interactive and immersive experiences designed to make learning
              more impactful. Forget passive listening—at TechCon, participants
              engage with technology in real-time, collaborate with peers, and
              develop actionable strategies for their own projects.
            </p>
            <h4>Interactive Features:</h4>
            <ul>
              <li>
                <strong> Blockchain in Action</strong>: Immerse yourself in live
                demonstrations of blockchain technology. Witness how smart
                contracts function and learn the ins and outs of a decentralized
                ledger in a hands-on environment.
              </li>
              <li>
                <strong>Real-World Case Studies</strong>: Delve into detailed
                analyses of successful blockchain implementations across
                different sectors. Understand the challenges and successes of
                these projects and how you can apply them in your own work.
              </li>
              <li>
                <strong>Collaborative Brainstorming</strong>: Participate in
                group workshops where you will brainstorm and develop blockchain
                use cases tailored to your industry, whether it’s government,
                finance, or social impact.
              </li>
            </ul>
          </div>
          <div className="flow">
            <h3>Why Attend TechCon?</h3>
            <ul>
              <li>
                <strong>Expand Your Network</strong>: Connect with technology
                experts, innovators, and policymakers from across Africa and
                beyond.
              </li>
              <li>
                <strong>Gain Practical Knowledge</strong>: Learn how to
                implement cutting-edge technologies like blockchain, AI, and IoT
                in your own organization.
              </li>
              <li>
                <strong>Be Part of Africa’s Digital Revolution</strong>:
                Discover how emerging technologies are shaping Africa’s future
                and positioning the continent as a global leader in innovation.
              </li>
            </ul>
            <div>
              <p>
                Join us at TechCon and be part of Africa’s digital
                transformation. Whether you're a government official,
                entrepreneur, or technology enthusiast, TechCon offers the
                insights, tools, and connections you need to lead in the age of
                technology.
              </p>
            </div>
          </div>
        </article>
        <article className="wrapper__Article flow">
          {/* <div className="title__case-card">
            <h2 className="heading__case-card">More On Projects</h2>
          </div> */}

          {/* Card 1 */}
          <div className="row-case-card__Article">
            {/* <div className="case-card__Article">
              <img
                src="/images/projects/celo.svg"
                alt="TTT21"
                className="Image__case-card"
              />
              <h4 className="topHeading__case-card title__top">Case Study</h4>
              <h3 className="heading__case-card">TTT 2021</h3>
              <p className="description__case-card">
                UABA and Celo partnered to conduct Train-the-Train 2021 (TTT21)
                to educate people in Africa on blockchain technology and the
                Celo ecosystem.
              </p>
              <a href="/celo" className="smallAction interface cta__case-card">
                Learn More
              </a>
            </div> */}
            {/* Card 2 */}
            {/* <div className="case-card__Article">
              <img
                src="/images/projects/wit2023.svg"
                alt="TTT21"
                className="Image__case-card"
              />
              <h4 className="topHeading__case-card title__top">Case Study</h4>
              <h3 className="heading__case-card">WIT Blockchain Program</h3>
              <p className="description__case-card">
                UABA Women In Tech (WIT) partnered to conduct to women who were
                to train fellow women in 5 countries in Africa on Blockchain
                Technology and its uses.
              </p>
              <a href="/" className="smallAction interface cta__case-card">
                Learn More
              </a>
            </div> */}
          </div>
        </article>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default Techcon;
